<template>
  <div>
    <div v-if="showHighlightedContent" class="table-operations">
      <ExpandButton
        :onClick="updateExpandedRows"
        :expandedRows="!allRowsExpanded"
      />
    </div>
    <a-table
      class="services-table"
      :columns="columns"
      :dataSource="services"
      :rowKey="(service) => service.alias || service.slug"
      :pagination="pagination"
      :loading="loading"
      :rowSelection="rowSelection"
      :rowClassName="rowClassName"
      :expandIconAsCell="showHighlightedContent"
      :expandedRowKeys="currentExpandedRows"
      :expandIconColumnIndex="-1"
      @change="handleTableChange"
      @expandedRowsChange="handleExpandedRowsChange"
    >
      <template slot="category_level_index" slot-scope="service">
        <a :href="`${service.href}/rubric`">
          <Grade
            :level="service.service_level.rubric.categoryLevel"
            :totalLevelCount="totalLevelCount"
            :shouldTruncate="true"
            :levels="levels"
            small
          />
        </a>
      </template>
      <template slot="scorecard_level_index" slot-scope="service">
        <a
          v-if="service.serviceStats"
          :href="`${service.href}/maturity-report?scorecardId[0]=${service.serviceStats.scorecards.nodes[0].categories.nodes[0].id}`"
        >
          <Grade
            :level="
              service.serviceStats.scorecards.nodes[0].categories.edges[0].level
            "
            :totalLevelCount="totalLevelCount"
            :shouldTruncate="true"
            :levels="levels"
            small
          />
        </a>
      </template>
      <template slot="service_level" slot-scope="service">
        <a :href="`${service.href}/rubric`">
          <Grade
            :level="level(service)"
            :totalLevelCount="totalLevelCount"
            :shouldTruncate="true"
            :levels="levels"
            small
          />
          <GradeProgress
            v-if="service.serviceStats"
            :nextLevel="service.serviceStats.rubric.checkResults.nextLevel"
          />
        </a>
      </template>
      <span slot="status_title"><a-icon type="alert" /> </span>
      <template slot="alert_status" slot-scope="service">
        <span style="display: flex; justify-content: left">
          <a-icon
            v-if="loadingAlertSourceStatus.includes(service.id)"
            type="loading"
            class="loading-alert-status-icon"
          />
          <a-tooltip
            v-else-if="allowAddServiceAlerts && !service.hasAlertSources"
            :title="addAlertSourceTooltip(service)"
          >
            <a-button
              shape="circle"
              class="add-alert-source-btn"
              :disabled="!service.permissions.canUpdate"
              @click="showAlertSourceModal(service)"
            >
              <a-icon type="plus" />
            </a-button>
          </a-tooltip>
          <a v-else :href="`${service.href}/operations`">
            <HealthBadge
              :status="
                (service.alertStatus && service.alertStatus.type) ||
                service.alert_status.type
              "
            />
          </a>
        </span>
      </template>
      <template v-slot:relationship="service">
        <IntegrationRelationshipLabel
          v-if="service.relationship"
          :relationship="service.relationship"
          :isLocked="service.lockedFromGraphqlModification"
        />
      </template>
      <template slot="type" slot-scope="service">
        <ComponentTypeBadge
          v-if="service.type"
          showTooltip
          class="type-icon"
          :name="serviceType(service)"
          :icon="iconName(service)"
          :color="iconColor(service)"
        />
      </template>
      <template slot="name" slot-scope="service">
        <div style="display: flex; flex-wrap: wrap">
          <div style="margin-right: 8px">
            <HighlightContentWithHref
              v-if="highlights(service, 'name')"
              :contentHtml="highlights(service, 'name')[0]"
              :href="service.href"
            />
            <a v-else :href="service.href">{{ service.name }}</a>
            <a-tooltip v-if="isLocked(service)">
              <span slot="title" v-html="lockedText" />
              <a-icon type="lock" />
            </a-tooltip>
            <a-tooltip v-if="hasServiceConfigError(service)">
              <p slot="title">
                There was an error when parsing this {{ $t("component") }}'s
                <code>opslevel.yml</code> file.
              </p>
              <span class="error"><a-icon type="exclamation-circle" /></span>
            </a-tooltip>
          </div>
          <span>
            <Stack
              :language="service.language"
              :framework="service.framework"
              :showIconOnly="true"
              :isStackClickable="isStackClickable"
              :formattedHighlight="stackHighlight(service)"
              @stackClick="filterStack"
            />
            <TagWithIcon
              v-if="service.creationSource"
              :input="service.creationSource"
              :showIconOnly="true"
              :iconMap="serviceCreationMap"
              :tagStyles="creationSourceTagStyles"
              :tooltipText="creationSourceToolTip(service.creationSource)"
              @onTagClick="filterCreationSource(service.creationSource)"
            />
          </span>
        </div>
      </template>
      <template slot="paths" slot-scope="service">
        <CollapsibleList :items="service.paths" :numPeeking="1">
          <template v-slot:item="{ slotProps }">
            <RepoPath :path="slotProps.item" />
          </template>
        </CollapsibleList>
      </template>
      <template slot="service_stat" slot-scope="service_stat">
        <ServiceStat
          v-if="service_stat"
          :num-checks="service_stat.num_checks"
          :num-passing-checks="service_stat.num_passing_checks"
        />
      </template>
      <template slot="owner" slot-scope="service">
        <HighlightContentWithHref
          v-if="service.owner && highlights(service, 'owner.name')"
          :contentHtml="highlights(service, 'owner.name')[0]"
          :href="service.owner.href"
        />
        <ServiceAttribute
          v-else
          :attribute="service.owner"
          type="team"
          missingAttributeText=""
        >
          <template v-slot:decorator>
            <a :href="service.owner.href">
              {{ service.owner.name }}
            </a>
          </template>
        </ServiceAttribute>
      </template>
      <template slot="tags" slot-scope="tags">
        <CollapsibleList
          :items="tags && tags.hasOwnProperty('nodes') ? tags.nodes : tags"
          :numPeeking="
            tags && tags.num_matching_tags ? tags.num_matching_tags : 1
          "
          :listDirectionVertical="
            isSmallScreenSize || tags.num_matching_tags > 1
          "
        >
          <template v-slot:item="{ slotProps }">
            <Tag
              :tag="slotProps.item"
              :deletable="false"
              :displayLength="
                slotProps.collapsed && !tags.num_matching_tags
                  ? tagDisplayLength
                  : 999
              "
              :wrapText="
                slotProps.collapsed && !tags.num_matching_tags ? false : true
              "
              :style="`max-width: ${tagDisplayLength}em`"
              @tagClick="filterTag"
            />
          </template>
        </CollapsibleList>
      </template>
      <template slot="tier" slot-scope="service">
        <ServiceAttribute
          :attribute="service.tier"
          type="tier"
          missingAttributeText=""
        >
          <template v-slot:decorator>
            <a-tooltip :title="service.tier.description">
              <div
                class="ant-tag tag"
                :style="tierTagStyles"
                @click="filterTier(service.tier)"
              >
                {{ service.tier.name }}
              </div>
            </a-tooltip>
          </template>
        </ServiceAttribute>
      </template>
      <template slot="lifecycle" slot-scope="service">
        {{ service.lifecycle && service.lifecycle.name }}
      </template>
      <span slot="lastDeployTitle">
        Last Deploy
        <a-tooltip>
          <template slot="title">
            The last production deploy. <br /><br />
            A deploy is considered production if its environment contains 'prod'
            or is empty.
          </template>
          <a-icon type="info-circle" />
        </a-tooltip>
      </span>
      <template slot="lastDeploy" slot-scope="service">
        <span v-if="lastDeployedAt(service)">
          <a :href="`${service.href}/operations?showLatestDeploy=true`">
            {{ lastDeployedAt(service) }} ago
          </a>
        </span>
      </template>
      <template slot="tools" slot-scope="service">
        <span v-if="tools(service).length > 0" style="display: flex">
          <a-tooltip
            v-for="(tool, index) in tools(service).slice(0, maxToolLength)"
            :key="`tool-${index}`"
            :title="toolsTooltipText(tool)"
          >
            <a :href="tool.url" target="_blank">
              <OpsIcon
                color="blue"
                :type="toolDisplayName(tool)"
                :domain="tool.url"
                style="font-size: 25px; padding: 0px 4px"
              />
            </a>
          </a-tooltip>
          <a
            v-if="tools(service).length > maxToolLength"
            :href="`${service.href}/operations`"
          >
            <a-button size="small"
              >+ {{ tools(service).length - maxToolLength }}</a-button
            >
          </a>
        </span>
      </template>
      <template slot="group" slot-scope="group">
        <span>
          <ServiceAttribute
            :attribute="group"
            type="team"
            missingAttributeText=""
          >
            <template v-slot:decorator>
              <a :href="group.href">
                {{ group.name }}
              </a>
            </template>
          </ServiceAttribute>
        </span>
      </template>
      <template
        v-if="showHighlightedContent"
        slot="expandedRowRender"
        slot-scope="service"
      >
        <ServiceDetails :service="service" />
      </template>
      <template slot="actions" slot-scope="service">
        <slot name="actions" :service="service" />
      </template>
    </a-table>
    <AlertSourceEditModal
      ref="alertSourceEditModal"
      :serviceId="selectedServiceId"
    />
  </div>
</template>

<script>
import Stack from "@/components/Stack.vue";
import Tag from "@/components/Tag.vue";
import CollapsibleList from "@/components/CollapsibleList.vue";
import ServiceStat from "@/components/ServiceStat.vue";
import RepoPath from "@/components/RepoPath.vue";
import IntegrationRelationshipLabel from "@/components/atoms/IntegrationRelationshipLabel.vue";
import Grade from "@/components/atoms/Grade.vue";
import GradeProgress from "@/components/atoms/GradeProgress.vue";
import OpsIcon from "@/components/atoms/OpsIcon.vue";
import LevelByCategoryVertical from "@/components/organisms/LevelByCategoryVertical.vue";
import HealthBadge from "@/components/atoms/HealthBadge.vue";
import ServiceAttribute from "@/components/molecules/ServiceAttribute.vue";
import AlertSourceEditModal from "@/services/show/tabs/operations/alertSources/AlertSourceEditModal.vue";
import TagWithIcon from "@/components/TagWithIcon.vue";
import HighlightContentWithHref from "@/components/atoms/HighlightContentWithHref.vue";
import ServiceDetails from "@/components/molecules/ServiceDetails.vue";
import ExpandButton from "@/components/ExpandButton.vue";
import { disabledMessage } from "@/shared/permissions_helper.js";

import {
  localeComparator,
  numericComparator,
  quotientComparator,
} from "@/shared/comparators.js";
import { OPSLEVEL_YAML_DOC } from "@/shared/links.js";
import {
  prepareTablePersistence,
  currentTableStateFromUrl,
  queryParamsFromTableState,
} from "@/shared/table_helper.js";

import { timeSince } from "@/shared/helpers.js";
import alertSources from "@/modules/alertSources/index.js";
import { permissions } from "@/mixins/permissions.js";
import { featureFlags } from "@/mixins/featureFlags.js";

import { ServiceCreationMap } from "@/shared/icons.js";
import { get, intersection } from "lodash";
import { mapActions, mapState } from "vuex";
import ComponentTypeBadge from "@/component_types/ComponentTypeBadge.vue";

const ALERT_SOURCE_FETCH_LIMIT = 100;
const BULK_MUTATION_LIMIT = 100;

// Columns that are rendered in the template above.
function defaultTemplateColumns() {
  return {
    alert_status: {
      key: "alert_status",
      slots: { title: "status_title" },
      scopedSlots: { customRender: "alert_status" },
      sorter: numericComparator("alert_status.index"),
    },
    type: {
      title: "Type",
      key: "component_type",
      scopedSlots: { customRender: "type" },
      sorter: localeComparator("type.name"),
      className: "type-column",
    },
    relationship: {
      title: "Relationship",
      key: "relationship",
      scopedSlots: { customRender: "relationship" },
    },
    level_index: {
      title: "Level",
      key: "level_index",
      scopedSlots: { customRender: "service_level" },
      sorter: numericComparator("service_level.rubric.level.index"),
    },
    scorecard_level_index: {
      title: "Scorecard Level",
      key: "scorecard_level",
      scopedSlots: { customRender: "scorecard_level_index" },
      sorter: true,
    },
    category_level_index: {
      title: "Category Level",
      key: "category_level_index",
      scopedSlots: { customRender: "category_level_index" },
    },
    name: {
      title: "Name",
      sorter: localeComparator("name"),
      key: "name",
      scopedSlots: { customRender: "name" },
    },
    paths: {
      title: "Paths",
      key: "paths",
      scopedSlots: { customRender: "paths" },
      sorter: localeComparator("paths[0].path"),
    },
    service_stat: {
      title: "Checks Passing",
      dataIndex: "service_stat",
      scopedSlots: { customRender: "service_stat" },
      sorter: quotientComparator(
        "service_stat.num_passing_checks",
        "service_stat.num_checks",
      ),
    },
    owner: {
      title: "Owner",
      key: "owner",
      sorter: localeComparator("owner.name"),
      scopedSlots: { customRender: "owner" },
    },
    tier: {
      title: "Tier",
      key: "tier",
      sorter: numericComparator("tier.index"),
      scopedSlots: { customRender: "tier" },
    },
    product: {
      title: "Product",
      dataIndex: "product",
      sorter: localeComparator("product"),
    },
    tags: {
      title: "Tags",
      key: "tags",
      dataIndex: "tags",
      scopedSlots: { customRender: "tags" },
    },
    lifecycle: {
      title: "Lifecycle",
      key: "lifecycle",
      sorter: numericComparator("lifecycle.index"),
      scopedSlots: { customRender: "lifecycle" },
    },
    last_deploy: {
      key: "last_deploy",
      slots: { title: "lastDeployTitle" },
      scopedSlots: { customRender: "lastDeploy" },
      sorter: localeComparator("lastDeploy.deployedAt", false),
    },
    tools: {
      title: "Tools",
      key: "tools",
      scopedSlots: { customRender: "tools" },
    },
    group: {
      title: "Group",
      key: "group",
      dataIndex: "owner.group",
      scopedSlots: { customRender: "group" },
    },
    actions: {
      title: "Actions",
      key: "actions",
      scopedSlots: { customRender: "actions" },
    },
  };
}

export default {
  components: {
    CollapsibleList,
    Grade,
    OpsIcon,
    RepoPath,
    ServiceAttribute,
    ServiceStat,
    Stack,
    HealthBadge,
    Tag,
    AlertSourceEditModal,
    TagWithIcon,
    HighlightContentWithHref,
    IntegrationRelationshipLabel,
    ServiceDetails,
    ExpandButton,
    GradeProgress,
    ComponentTypeBadge,
  },

  mixins: [permissions, featureFlags],

  props: {
    services: {
      type: Array,
      required: true,
    },
    visibleColumns: {
      type: Array,
      default: () => {
        const columns = Object.keys(defaultTemplateColumns());

        return columns.filter((col) => col !== "paths");
      },
    },
    defaultSort: {
      type: Object,
      required: false,
      default: () => ({ column: "name", order: "ascend" }),
      validator: (defaultSort) => {
        return (
          typeof defaultSort === "object" &&
          typeof defaultSort.column === "string" &&
          typeof defaultSort.order === "string"
        );
      },
    },
    pagination: {
      required: false,
      default: false,
    },
    sortState: {
      type: Object,
      required: false,
      default: () => {},
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    totalLevelCount: {
      type: Number,
      required: false,
      default: null,
    },
    isStackClickable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTierClickable: {
      type: Boolean,
      required: false,
      default: false,
    },
    fixedWidths: {
      type: Array,
      required: false,
      default: null,
    },
    categoryName: {
      type: String,
      required: false,
      default: null,
    },
    allowAddServiceAlerts: {
      type: Boolean,
      required: false,
      default: false,
    },
    servicesNamespace: {
      required: false,
      type: String,
      default: "services",
    },
    allowSelection: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedServices: {
      type: Array,
      required: false,
      default: () => [],
    },
    showHighlightedContent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      tagDisplayLength: 40,
      isSmallScreenSize: false,
      // Columns that are rendered dynamically instead of in a template.
      // Can be used to override the default styling of the td element.
      defaultRenderedColumns: {
        levels_by_category: {
          title: "Category Breakdown",
          key: "levels_by_category",
          scopedSlots: { customRender: "levels_by_category" },
          customRender: (service) => {
            return {
              attrs: {
                class: "level-by-category-col",
              },
              children: this.$createElement(LevelByCategoryVertical, {
                props: {
                  levelsByCategory: this.levelsByCategory(service),
                  totalLevelCount: this.totalLevelCount,
                  uncategorizedLevel: this.uncategorizedLevel(service),
                  showUncategorized: this.showUncategorized,
                  levels: this.levels,
                },
              }),
            };
          },
        },
      },
      maxToolLength: 6,
      selectedServiceId: "",
      loadingAlertSourceStatus: [],
      selectedRowKeys: [],
      currentExpandedRows: [],
      serviceCreationMap: ServiceCreationMap,
    };
  },

  computed: {
    ...mapState({
      hasMonitoringIntegrations: (state) =>
        state.alertSources.hasMonitoringIntegrations,
      alertSourceEdges: (state) => state.alertSources.alertSourceEdges,
      levels: (state) => state.levels.levels,
    }),
    servicesState() {
      return this.$store.state[this.servicesNamespace];
    },
    columns() {
      const { sortState } = this;
      const defaultColumns = this.defaultColumns();

      // add columns in the same order of the given `visibleColumns`
      const columns = this.visibleColumns.reduce(
        (acc, col) => ({ ...acc, [col]: defaultColumns[col] }),
        {},
      );

      if (!this.hasFeatureFlag("component_types")) {
        delete columns["type"];
      }

      if (!this.hasMonitoringIntegrations) {
        delete columns["alert_status"];
      }

      if (this.categoryName) {
        columns["category_level_index"].title = this.categoryName;
      }

      if (
        columns["level_index"] &&
        this.services.some((service) => service.level)
      ) {
        columns["level_index"].sorter = numericComparator("level.index");
      }

      if (sortState && sortState.key != undefined) {
        for (const column in columns) {
          if (columns[column].key === sortState.key) {
            columns[column].sortOrder = sortState.direction;
          }
        }
      } else if (this.defaultSort) {
        const col = this.defaultSort.column;
        const order = this.defaultSort.order;

        if (columns[col]) {
          columns[col].defaultSortOrder = order;
        }
      }

      const columnValues = Object.values(columns);

      if (this.fixedWidths) {
        for (
          let i = 0;
          i < this.fixedWidths.length && i < columnValues.length;
          i++
        ) {
          columnValues[i].width = this.fixedWidths[i];
        }
      }

      if (columns["type"]) {
        columns["type"].width = 55;
      }

      return columnValues;
    },
    lockedText() {
      return `This ${this.$t(
        "component",
      )} is locked because it is managed by <a href="${OPSLEVEL_YAML_DOC}" target="_blank">opslevel.yml</a>.`;
    },
    showUncategorized() {
      // We always want to show the same number of columns/categories in Levels by Category, for each service.
      // If at least 1 service has an Uncategorized level, we show the Uncategorized column for all services.
      return this.services.some((s) => this.uncategorizedLevel(s));
    },
    allRowsExpanded() {
      return this.currentExpandedRows.length < this.services.length;
    },
    rowSelection() {
      if (!this.allowSelection) {
        return null;
      } else {
        return {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onRowSelectChange,
          getCheckboxProps: (service) => ({
            props: {
              disabled: service.locked,
            },
          }),
        };
      }
    },
    creationSourceTagStyles() {
      let styles = "cursor: pointer;";

      styles +=
        "border-color: #91D5FF;color: #1890ff;background-color: #E6F7FF;";

      return styles;
    },
    servicesWithSummaryHighlights() {
      if (!this.showHighlightedContent) {
        return [];
      }

      const summaryProperties = [
        "aliases.value",
        "friendly_id_slugs.slug",
        "product",
        "description",
        "notes.content",
      ];

      const rowKeys = this.services.map((service) => {
        if (service.highlights) {
          const highlightKeys = Object.keys(service.highlights);
          const summaryKeys = intersection(highlightKeys, summaryProperties);

          if (summaryKeys.length) {
            return service.alias;
          }
        }
      });

      return rowKeys.filter((element) => element);
    },
    tierTagStyles() {
      return this.isTierClickable
        ? "cursor: pointer !important;"
        : "cursor: default !important;";
    },
  },

  watch: {
    selectedServices(selectedServices) {
      this.selectedRowKeys = selectedServices;
    },
    services() {
      this.setCurrentExpandedRows();
    },
  },

  created() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
    this.$store.registerModuleOnce("alertSources", alertSources);
    this.fetchHasMonitoringIntegration();
    this.$store.watch(
      (state) => state.alertSources.isCreatingAlert,
      (isCreatingAlert) => {
        if (!isCreatingAlert) {
          this.loadingAlertSourceStatus.push(this.selectedServiceId);
          // currentServiceId is its own const so that we remove the correct service id from loadingAlertSourceStatus in the case that a user
          // adds alerts sources to different services back to back
          const currentServiceId = this.selectedServiceId;

          setTimeout(() => {
            let queryParams;

            if (this.servicesState) {
              queryParams = queryParamsFromTableState(this.servicesState);
            } else {
              queryParams = currentTableStateFromUrl();
            }

            this.handleTableChange(
              this.pagination,
              null,
              queryParams.sorter,
              null,
            );
            this.loadingAlertSourceStatus =
              this.loadingAlertSourceStatus.filter(
                (serviceId) => serviceId !== currentServiceId,
              );
          }, 6000);
        }
      },
    );

    // we need to call this on create because a user can load the tech docs search tab and then switch
    // over to the services tab after which would mean that the `services` watcher does not fire
    this.setCurrentExpandedRows();
  },

  methods: {
    ...mapActions({
      fetchAlertData: "alertSources/fetchAlertData",
      updateTableData: "services/updateTableData",
      fetchHasMonitoringIntegration:
        "alertSources/fetchHasMonitoringIntegration",
    }),
    handleTableChange(pagination, filters, sorter, data) {
      this.$emit("tableChange", pagination, filters, sorter, data);
    },
    handleExpandedRowsChange(expandedRows) {
      this.currentExpandedRows = expandedRows;
    },
    defaultColumns() {
      return Object.assign(
        defaultTemplateColumns(),
        this.defaultRenderedColumns,
      );
    },
    filterTag(tag) {
      const queryParams = {
        filters: {
          predicates: [
            {
              arg: tag.value,
              key: "tags",
              secondary_key: tag.key,
              type: "equals",
            },
          ],
        },
        pagination: { current: 1 },
      };

      this.updateTableData(prepareTablePersistence({ queryParams }, 1));
    },
    filterTier(tier) {
      if (!this.isTierClickable) {
        return;
      }

      const queryParams = {
        filters: {
          predicates: [
            { arg: tier.index.toString(), key: "tier_index", type: "equals" },
          ],
        },
        pagination: { current: 1 },
      };

      this.updateTableData(prepareTablePersistence({ queryParams }, 1));
    },
    filterStack(stack) {
      const queryParams = {
        filters: {
          predicates: [{ arg: stack.value, key: stack.key, type: "equals" }],
        },
        pagination: { current: 1 },
      };

      this.updateTableData(prepareTablePersistence({ queryParams }, 1));
    },
    filterCreationSource(creationSource) {
      const queryParams = {
        filters: {
          predicates: [
            { arg: creationSource, key: "creation_source", type: "equals" },
          ],
        },
        pagination: { current: 1 },
      };

      this.updateTableData(prepareTablePersistence({ queryParams }, 1));
    },
    hasServiceConfigError(service) {
      return service.has_service_config_error || service.hasServiceConfigError;
    },
    isLocked(service) {
      return service.is_locked || service.locked;
    },
    level(service) {
      // services returned by the REST API have level defined on them directly
      // services returned by GraphQL API have level nested under service_level
      return service.level || get(service, "service_level.rubric.level");
    },
    onResize() {
      if (window.innerWidth < 1440) {
        this.tagDisplayLength = 20;
        this.isSmallScreenSize = true;
      } else {
        this.tagDisplayLength = 40;
        this.isSmallScreenSize = false;
      }
    },
    addAlertSourceTooltip(service) {
      if (service.permissions.canUpdate) {
        return "Add alert source";
      } else {
        return disabledMessage(
          `add an alert source to this ${this.$t("component")}`,
        );
      }
    },
    levelsByCategory(service) {
      const ret = [
        ...get(service, "service_level.rubric.categories.edges", []),
      ];
      const scorecards = get(service, "service_level.scorecards.nodes", []).map(
        (s) => s.categories.edges[0],
      );

      if (scorecards.length <= 3) {
        return ret.concat(scorecards);
      }

      let minLevel = null;

      scorecards.forEach((s) => {
        if (s.level && (!minLevel || s.level.index < minLevel.index)) {
          minLevel = s.level;
        }
      });
      ret.push({
        level: minLevel,
        node: { name: `${scorecards.length} Scorecards` },
      });

      return ret;
    },
    uncategorizedLevel(service) {
      return get(service, "service_level.rubric.categoryLevel");
    },
    toolsTooltipText(tool) {
      if (tool.displayName) {
        return `View ${tool.displayCategory.toLowerCase()} - ${
          tool.displayName
        }`;
      }

      if (tool.display_name) {
        return `View ${tool.display_category.toLowerCase()} - ${
          tool.display_name
        }`;
      }

      if (tool.displayCategory) {
        return `View ${tool.displayCategory.toLowerCase()}`;
      }

      return `View ${tool.display_category.toLowerCase()}`;
    },
    lastDeployedAt(service) {
      if (service.last_deploy && service.last_deploy.deployed_at) {
        return timeSince(service.last_deploy.deployed_at);
      }

      if (service.lastDeploy && service.lastDeploy.deployedAt) {
        return timeSince(service.lastDeploy.deployedAt);
      }
    },
    tools(service) {
      if (!service.tools) {
        return [];
      }

      if (Array.isArray(service.tools)) {
        return service.tools;
      }

      if (service.tools.nodes) {
        return service.tools.nodes;
      }
    },
    toolDisplayName(tool) {
      if (tool.displayName) {
        return tool.displayName;
      }

      if (tool.display_name) {
        return tool.display_name;
      }
    },
    creationSourceToolTip(creationSource) {
      return (
        "Created by " + this.serviceCreationMap[creationSource.toLowerCase()]
      );
    },
    showAlertSourceModal(service) {
      this.selectedServiceId = service.id;
      this.fetchAlertData({
        first: ALERT_SOURCE_FETCH_LIMIT,
        service_id: service.id,
      });
      this.$refs.alertSourceEditModal.show();
    },
    onRowSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys.slice(0, BULK_MUTATION_LIMIT);
      this.$emit("selectedServicesChange", this.selectedRowKeys);
    },
    rowClassName(record) {
      return record.locked ? "locked-service" : null;
    },
    stackHighlight(service) {
      const highlights = {};

      if (this.showHighlightedContent) {
        const language_highlight = get(service, "highlights.language.0");

        if (language_highlight) {
          highlights["language"] = language_highlight;
        }

        const framework_highlight = get(service, "highlights.framework.0");

        if (framework_highlight) {
          highlights["framework"] = framework_highlight;
        }
      }

      return highlights;
    },
    highlights(service, property) {
      if (!service.highlights) {
        return null;
      }

      return service.highlights[property];
    },
    updateExpandedRows() {
      if (this.allRowsExpanded) {
        this.currentExpandedRows = this.services.map(
          (service) => service.alias,
        );
      } else {
        this.currentExpandedRows = [];
      }
    },
    setCurrentExpandedRows() {
      if (this.showHighlightedContent) {
        this.currentExpandedRows = this.servicesWithSummaryHighlights;
      }
    },
    serviceType(service) {
      return service.type.name;
    },
    iconName(service) {
      return service.type.icon.name;
    },
    iconColor(service) {
      return service.type.icon.color;
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.level-by-category-col) {
  /* Override the default td padding set by antd.
  The default styling targets the td element directly which
  has higher specificity than targeting a class name.
  So we have to use !important here. */
  padding: 8px !important;
}

:deep(.locked-service) .ant-checkbox-disabled {
  display: none;
}
.tag {
  cursor: pointer;
}
.add-alert-source-btn:enabled {
  border-style: dashed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-alert-status-icon {
  border-radius: 50%;
  padding: 10px;
  background-color: rgba(191, 191, 191, 0.2);
}

.services-table {
  overflow-x: auto;
}

:deep(.type-column) {
  padding: 0;
  text-align: center;
}

:deep(.type-icon) {
  display: inline-flex;
}
</style>
