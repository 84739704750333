<template>
  <a-button
    class="task-button"
    type="primary"
    @click="$emit('onToggleTaskDrawer')"
  >
    <span
      :class="{
        'task-button-content': true,
        'sider-collapsed': collapsed,
      }"
    >
      <span style="margin-left: 4px">
        <OpsIcon type="ai_sparkle" />
        <span v-if="!collapsed" style="padding-left: 10px"
          >Recommendations</span
        >
      </span>
      <span style="padding-left: 5px; padding-right: 4px">{{
        taskCount > 10 ? "10+" : taskCount
      }}</span>
    </span>
  </a-button>
</template>

<script>
import OpsIcon from "@/components/atoms/OpsIcon.vue";

export default {
  components: {
    OpsIcon,
  },

  props: {
    taskCount: {
      type: Number,
      required: false,
      default: 0,
    },
    collapsed: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    handleOpenTaskDrawer() {
      if (this.$refs.taskDrawer.visible) {
        this.$refs.taskDrawer.close();
      } else {
        this.$refs.taskDrawer.open();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.task-button {
  margin: 0 4px;
  width: calc(100% - 8px);
  height: 100%;
  padding: 5px;
  color: #ffffff;
  background: #ffffff26;
  border: 1px solid #d9d9d926;

  &:hover {
    background: #1890ff;
  }
}

.task-button-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-shadow: none;

  &.sider-collapsed {
    justify-content: center;
  }
}
</style>
