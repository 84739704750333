<template>
  <a-card style="padding: 0; margin-bottom: 8px">
    <div class="suggestion-content">
      <div class="suggestion-text">
        <h3 v-if="suggestion.action.type === 'create_service'">
          Register '{{ suggestion.name }}' as a new {{ $t("component") }}
        </h3>
        <h3 v-else>
          Merge into '{{ pluckAction(suggestion, "serviceName") }}'
        </h3>

        <p>
          We discovered
          <b v-if="suggestion.action.type === 'create_service'">{{
            pluckAction(suggestion, "serviceName")
          }}</b
          ><span v-else>
            <b>{{ suggestion.aliases[0] }}</b
            >{{
              suggestion.aliases.length == 1
                ? ""
                : ` and +${suggestion.aliases.length - 1} more aliases`
            }}</span
          ><span v-if="suggestion.sources.edges[0].node">
            from
            <a :href="suggestion.sources.edges[0].node.url" target="_blank">{{
              capitalize(suggestion.sources.edges[0].node.type)
            }}</a
            >{{
              suggestion.sources.edges.length == 1
                ? ""
                : ` and +${suggestion.sources.edges.length - 1}
              more sources`
            }}</span
          >. {{ belongsToText }}
          <a-tooltip v-if="usingSuggestedOwner">
            <template slot="title">
              <p>
                The following users contributed to this repository recently:
              </p>
              <ul>
                <li v-for="c in getContributors(suggestion)" :key="c.user.id">
                  <a :href="c.user.htmlUrl">{{ c.user.name }}</a
                  >: {{ c.contributionCount }}
                  {{ c.contributionCount === 1 ? "time" : "times" }}
                </li>
              </ul>
            </template>
            <OpsIcon type="ai_sparkle" :style="{ paddingRight: '2px' }" />
            <a :href="ownerHref" class="owner-link">{{ ownerName }}</a>
          </a-tooltip>
          <a v-else :href="ownerHref" class="owner-link">{{ ownerName }}</a
          >.
          <br />
          <span v-if="suggestion.action.type === 'create_service'"
            >Accept to register it as a new {{ $t("component") }} in your
            catalog.</span
          >
          <span v-else
            >Merge these aliases and any associated {{ $t("component") }} data
            into <span v-html="serviceLink"></span>.</span
          >
        </p>
      </div>
      <div class="suggestion-actions">
        <a-popconfirm
          placement="bottomLeft"
          :okText="
            suggestion.action.type === 'create_service' ? 'Accept' : 'Merge'
          "
          cancelText="Cancel"
          arrowPointAtCenter
          @confirm="handleAccept(suggestion)"
        >
          <template slot="title">
            <div
              v-if="suggestion.action.type === 'create_service'"
              class="popconfirm-text"
            >
              <p>Accept recommendation?</p>
              <p>
                <b>{{ pluckAction(suggestion, "serviceName") }}</b>
                will be added to the catalog, with
                <b>{{ ownerName ? ownerName : "no team" }}</b>
                as the assigned owner.
              </p>
            </div>
            <div v-else class="popconfirm-text">
              <p>Merge recommendation?</p>
              <p>
                The following will be merged into
                <span v-html="serviceLink"></span>:
              </p>
              <ul>
                <li
                  v-for="(alias, aliasIndex) in suggestion.aliases"
                  :key="aliasIndex"
                >
                  <b>{{ alias }}</b>
                </li>
              </ul>
            </div>
          </template>
          <a-button
            v-if="suggestion.action.type === 'create_service'"
            :disabled="loading"
            type="primary"
            icon="check"
            class="accept-button"
          >
            Accept
          </a-button>
          <a-button
            v-else
            :disabled="loading"
            type="primary"
            icon="check"
            class="accept-button"
          >
            Merge
          </a-button>
        </a-popconfirm>
        <a-popconfirm
          v-if="suggestion.action.type === 'create_service'"
          class="reassign-popconfirm"
          placement="bottom"
          okText="Update suggested team"
          cancelText="I don't know"
          arrowPointAtCenter
          :disabled="loading"
          @confirm="handleAssignOwner(suggestion)"
          @cancel="handleAssignOwner(suggestion, null)"
        >
          <template slot="title">
            <div class="popconfirm-text">
              <p>
                Do you know which team owns
                <b>{{ pluckAction(suggestion, "serviceName") }}</b
                >?
              </p>
              <Selector
                namespace="teams"
                stateList="teams"
                loadingState="isFetchingTeams"
                fetchMethod="fetchTeams"
                placeholder="Select a Team"
                :defaultOption="null"
                valueKey="id"
                style="width: 100%; margin-bottom: 4px"
                @select="handleNewOwnerSelected"
              />

              <p style="color: #8c8c8c; margin-bottom: 0px">
                The selected team will be assigned as the suggested owner.
              </p>
            </div>
          </template>
          <a-button class="reject-button">
            This is not my {{ $t("component") }}
          </a-button>
        </a-popconfirm>
        <a-button class="edit-button">
          <a :href="editLink">
            {{ editText }}
            <a-icon type="export" />
          </a>
        </a-button>
      </div>
    </div>
  </a-card>
</template>

<script>
import OpsIcon from "@/components/atoms/OpsIcon.vue";
import Selector from "@/components/Selector.vue";
import { featureFlags } from "@/mixins/featureFlags.js";
import { mapState, mapActions } from "vuex";
import teamsModule from "@/modules/teams/index.js";
import { get, capitalize } from "lodash";

export default {
  components: {
    OpsIcon,
    Selector,
  },

  mixins: [featureFlags],
  inject: ["routes"],

  props: {
    suggestion: {
      type: Object,
      required: true,
    },
    filteredRecommendationsLink: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      ownerToAssign: null,
    };
  },

  computed: {
    ...mapState({
      totalSuggestions: (state) =>
        state.serviceSuggestionsCard.totalSuggestions,
      suggestionActions: (state) =>
        state.serviceSuggestionsCard.suggestionActions,
    }),
    serviceLink() {
      const serviceHref = this.pluckAction(this.suggestion, "serviceHref");

      if (serviceHref) {
        return `<a href="${serviceHref}" target="_blank">${this.pluckAction(
          this.suggestion,
          "serviceName",
        )}</a>`;
      }

      return "";
    },
    directOwner() {
      return get(this.suggestion, "action.owner");
    },
    suggestedOwners() {
      return this.suggestion.suggestedOwners?.nodes?.map((o) => o?.team) ?? [];
    },
    usingSuggestedOwner() {
      if (this.directOwner) {
        return false;
      }

      const suggestedOwnerIds = this.suggestedOwners.map((o) => o.id);
      const ownerId = this.pluckAction(this.suggestion, "owner")?.id;

      return suggestedOwnerIds.includes(ownerId);
    },
    belongsToText() {
      if (this.suggestion?.action?.type === "create_service") {
        return "It seems like it might belong to";
      } else {
        return "This belongs to";
      }
    },
    ownerName() {
      return this.ownerWithSuggestedOwnerFallback(this.suggestion)?.name;
    },
    ownerHref() {
      return this.ownerWithSuggestedOwnerFallback(this.suggestion)?.href;
    },
    editText() {
      return this.suggestion.action.type === "attach_historical_events"
        ? "Review"
        : "Review & Edit";
    },
    editLink() {
      return `${this.filteredRecommendationsLink}&highlightedSuggestionId=${this.suggestion.id}`;
    },
  },

  created() {
    this.$store.registerModuleOnce("teams", teamsModule);
  },

  methods: {
    ...mapActions({
      actionSuggestions: "serviceSuggestionsCard/actionSuggestions",
      updateSuggestionInState: "serviceSuggestionsCard/updateSuggestionInState",
      updateSuggestion: "serviceSuggestionsCard/updateSuggestion",
    }),
    capitalize,
    mapModifiedSuggestions(suggestionId) {
      const suggestion = this.totalSuggestions.find(
        (s) => suggestionId === s.id,
      );

      const action = get(this.suggestionActions, `${suggestion.id}.action`, {});

      return {
        suggestionId: action.id || suggestion.id,
        type: action.type || get(suggestion, "action.type"),
        name: action.serviceName || get(suggestion, "action.serviceName"),
        description:
          action.serviceDescription ??
          get(suggestion, "action.serviceDescription"),
        owner:
          action.owner ??
          get(suggestion, "action.owner") ??
          get(suggestion, "owner") ??
          get(suggestion, "suggestedOwner"),
        serviceId: action.serviceId || get(suggestion, "action.serviceId"),
        suggestionParamsModified:
          action.suggestion_params_modified ||
          get(suggestion, "suggestion_params_modified", false),
        suggestionActionModified:
          action.suggestion_action_modified ||
          get(suggestion, "suggestion_action_modified", false),
      };
    },
    handleAccept(suggestion) {
      this.actionSuggestions({
        suggestions: [this.mapModifiedSuggestions(suggestion.id)],
        showGroupedSuggestions: this.hasFeatureFlag("suggestion_groups"),
      });
    },
    pluckAction(suggestion, path = null) {
      const suggestionOverride = get(
        this.suggestionActions,
        `${suggestion.id}.action`,
        {},
      );

      const mergedState = Object.assign(
        {},
        get(suggestion, "action", {}),
        suggestionOverride,
      );
      const action = path ? get(mergedState, path) : mergedState;

      return path === "serviceName" ? this.sanitize(action) : action;
    },
    ownerWithSuggestedOwnerFallback(suggestion) {
      const directOwner = this.directOwner;
      const suggestedOwner = get(suggestion, "suggestedOwner");
      const assignedOwner = get(suggestion, "owner");

      if (directOwner && !(suggestion.action.type == "create_service")) {
        return directOwner;
      } else if (this.pluckAction(suggestion, "owner")) {
        return this.pluckAction(suggestion, "owner");
      } else if (assignedOwner) {
        if (!this.pluckAction(suggestion, "owner")) {
          this.updateSuggestionInState({
            id: suggestion.id,
            owner: assignedOwner,
          });
        }

        return assignedOwner;
      } else if (suggestedOwner) {
        if (!this.pluckAction(suggestion, "owner")) {
          this.updateSuggestionInState({
            id: suggestion.id,
            owner: suggestedOwner,
          });
        }

        return suggestedOwner;
      }
    },
    getContributors(suggestion) {
      const selectedOwnerId =
        this.ownerWithSuggestedOwnerFallback(suggestion)?.id;

      const selectedSuggestedOwner = suggestion.suggestedOwners.nodes.find(
        (owner) => owner.team.id === selectedOwnerId,
      );

      return selectedSuggestedOwner?.contributors.slice(0, 3);
    },
    handleNewOwnerSelected(owner) {
      this.ownerToAssign = owner.id;
    },
    handleAssignOwner(suggestion, ownerToAssign = this.ownerToAssign) {
      this.updateSuggestion({
        suggestion: suggestion.id,
        owner: { id: ownerToAssign },
      });
    },
  },
};
</script>

<style scoped>
.popconfirm-text {
  max-width: 400px;
  margin-bottom: 8px;
}
</style>
