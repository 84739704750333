<template>
  <a-tooltip :title="tooltipTitle" :placement="item.warning_text_placement">
    <a
      v-if="!item.disabled"
      v-bind="item.attrs"
      :href="item.url"
      :target="linkTarget"
      @click="handleClick"
    >
      <span class="component-menu-item-wrapper">
        <a-icon v-if="item.icon" :type="item.icon" />
        <OpsIcon v-else-if="item.customIcon" :type="item.customIcon" />
        <div v-else-if="item.component_type">
          <ComponentTypeBadge
            :name="item.component_type.name"
            :icon="item.component_type.icon"
            :color="item.component_type.color"
            small
          />
        </div>
        <span>
          {{ item.display_text }}
          <span
            v-if="item.tag_text"
            :style="{
              marginLeft: '5px',
              color: textColor,
              fontWeight: 'bold',
              fontSize: '12px',
            }"
          >
            {{ item.tag_text }}
          </span>
          <CounterBadge
            v-if="item.badge_count"
            :count="item.badge_count"
            :type="item.badge_type || 'error'"
            :overflowCount="10"
            :offset="[3, -2]"
          />
          <a-icon
            v-else-if="item.warning && item.warning_type"
            type="warning"
            theme="filled"
            :style="{ color: 'rgb(250, 173, 20)' }"
          />
          <a-badge v-else-if="item.warning" status="error" />
        </span>
      </span>
    </a>
    <span v-else>
      <a-icon v-if="item.icon" :type="item.icon" />
      <OpsIcon v-else-if="item.customIcon" :type="item.customIcon" />
      <span>
        {{ item.display_text }}
        <span
          v-if="item.tag_text"
          :style="{
            marginLeft: '5px',
            color: textColor,
            fontWeight: 'bold',
            fontSize: '12px',
          }"
        >
          {{ item.tag_text }}
        </span>
        <CounterBadge
          v-if="item.badge_count"
          :count="item.badge_count"
          :type="item.badge_type || 'error'"
          :overflowCount="10"
          :offset="[3, -2]"
        />
        <a-icon
          v-else-if="item.warning && item.warning_type"
          type="warning"
          theme="filled"
          :style="{ color: 'rgb(250, 173, 20)' }"
        />
        <a-badge v-else-if="item.warning" status="error" />
      </span>
    </span>
  </a-tooltip>
</template>

<script>
import OpsIcon from "@/components/atoms/OpsIcon.vue";
import CounterBadge from "@/components/CounterBadge.vue";
import ComponentTypeBadge from "@/component_types/ComponentTypeBadge.vue";
import { featureFlags } from "@/mixins/featureFlags.js";
import { isEmpty } from "lodash";

function isValidItem(item) {
  if (typeof item.display_text !== "string") {
    return false;
  }

  if (item.icon !== undefined && typeof item.icon !== "string") {
    return false;
  }

  if (item.customIcon !== undefined && typeof item.customIcon !== "string") {
    return false;
  }

  if (item.url !== undefined && typeof item.url !== "string") {
    return false;
  }

  if (item.active !== undefined && typeof item.active !== "boolean") {
    return false;
  }

  if (item.attrs !== undefined && typeof item.attrs !== "object") {
    return false;
  }

  if (
    item.tag_text !== undefined &&
    item.tag_text !== null &&
    typeof item.tag_text !== "string"
  ) {
    return false;
  }

  if (item.target_new !== undefined && typeof item.target_new != "boolean") {
    return false;
  }

  if (item.click !== undefined && typeof item.click !== "function") {
    return false;
  }

  return true;
}

export default {
  components: { OpsIcon, CounterBadge, ComponentTypeBadge },
  mixins: [featureFlags],

  props: {
    item: {
      type: Object,
      required: true,
      validator: isValidItem,
    },
  },

  computed: {
    tooltipTitle() {
      return this.item.warning_text_condition ? this.item.warning_text : "";
    },
    textColor() {
      return this.item.active ? "white" : "#1890FF";
    },
    linkTarget() {
      return this.item.target_new ? "_blank" : "";
    },
  },

  methods: {
    handleClick(e) {
      if (this.item.click) {
        this.item.click(e);
      }
    },
    isEmpty,
  },
};
</script>

<style scoped>
.component-menu-item-wrapper {
  display: flex;
  align-items: center;
}
</style>
