<template>
  <div>
    <a-drawer
      placement="left"
      :getContainer="false"
      :visible="visible"
      width="60%"
      :bodyStyle="{ padding: '0px' }"
      @close="close"
    >
      <span slot="title">
        <OpsIcon type="ai_sparkle" /> Recommendations
        <CounterBadge
          :count="taskCount"
          :type="taskCount === 0 ? 'primary' : 'error'"
        />
      </span>

      <div class="drawer-container">
        <div v-if="count === 0" class="center">
          <EmptyState>
            <img
              slot="image"
              src="/status_images/up_to_date.svg"
              class="call-to-action-image"
            />
            <p
              slot="text"
              style="margin-bottom: 0px"
              v-html="noPendingTasksText"
            />
          </EmptyState>
        </div>

        <SuggestionsCardList v-else :teamIds="teamIds" />
      </div>
    </a-drawer>
  </div>
</template>

<script>
import CounterBadge from "@/components/CounterBadge.vue";
import EmptyState from "@/components/molecules/EmptyState.vue";
import SuggestionsCardList from "./SuggestionsCardList.vue";
import OpsIcon from "@/components/atoms/OpsIcon.vue";

export default {
  components: {
    CounterBadge,
    EmptyState,
    SuggestionsCardList,
    OpsIcon,
  },

  inject: ["currentUser"],

  props: {
    count: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      visible: false,
    };
  },

  computed: {
    noPendingTasksText() {
      return "<b>You're up to date.</b> If there are tasks you need to action on, they will appear here.";
    },
    taskCount() {
      return this.count > 10 ? "10+" : this.count;
    },
    teamIds() {
      return this.currentUser?.user?.teams?.map?.((t) => t.gid) ?? [];
    },
  },

  methods: {
    open() {
      this.visible = true;
      // this stops #main-content scrolling when drawer is open
      document.querySelector("body").style.overflow = "hidden";
    },
    close() {
      this.visible = false;
      document.querySelector("body").style.overflow = null;
    },
  },
};
</script>

<style scoped>
:deep(.ant-drawer) {
  z-index: 999;
}
.drawer-container {
  height: 100vh;
  padding: 24px;
  overflow-y: scroll;
}

.center {
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.call-to-action-image {
  width: 200px;
}

:deep(.ant-drawer-wrapper-body) {
  overflow: hidden;
}
</style>
