<template>
  <div>
    <div>
      <h3>
        {{ isFetchingSuggestions ? "" : filteredSuggestionsCount }}
        {{ $t("Component") }}
        {{
          filteredSuggestionsCount === 1 ? "Recommendation" : "Recommendations"
        }}
      </h3>
      <p>
        We detected new {{ $t("component") }} information that's not yet in your
        catalog. Help drive {{ $t("component") }} ownership by confirming the
        recommendations below.
      </p>
    </div>

    <div v-if="teamIds.length === 0" class="no-teams">
      <img
        src="/status_images/no_team_members.svg"
        class="call-to-action-image"
      />
      <p>
        <b>You're not on any teams.</b>
        <br />
        Join a team to get team-specific {{ $t("component") }} recommendations.
      </p>
    </div>

    <SpinningLogo v-else-if="isFetchingSuggestions" class="loading-spinner" />

    <div v-else-if="filteredSuggestionsCount > 0 || busy">
      <SuggestionsCard
        v-for="(suggestion, index) in suggestions"
        :key="index"
        :loading="busy"
        :suggestion="suggestion"
        :filteredRecommendationsLink="filteredRecommendationsLink"
      />
      <div
        v-if="filteredSuggestionsCount > 3"
        style="margin-top: 16px"
        class="link-to-detected-services"
      >
        <a-button>
          <a :href="filteredRecommendationsLink">
            Review all {{ filteredSuggestionsCount }}
            {{
              filteredSuggestionsCount === 1
                ? "recommendation"
                : "recommendations"
            }}
            on Detected {{ $t("Components") }}
          </a></a-button
        >
      </div>
    </div>

    <EmptyState v-else>
      <img
        slot="image"
        src="/status_images/up_to_date.svg"
        class="call-to-action-image"
      />
      <p
        slot="text"
        style="margin-bottom: 0px"
        v-html="noPendingSuggestionsText"
      />
    </EmptyState>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import EmptyState from "@/components/molecules/EmptyState.vue";
import SpinningLogo from "@/components/SpinningLogo.vue";
import serviceSuggestions from "@/modules/serviceSuggestions/index.js";
import SuggestionsCard from "./SuggestionsCard.vue";
import { featureFlags } from "@/mixins/featureFlags.js";
import {
  RECEIVE_ACTION_SUGGESTIONS_RESULTS,
  RECEIVE_ACTION_SUGGESTIONS_ERROR,
  RECEIVE_UPDATE_SUGGESTION_SUCCESS,
  RECEIVE_UPDATE_SUGGESTION_ERROR,
} from "@/modules/serviceSuggestions/mutation_types.js";
import { parseObjectToUrlParams } from "@/plugins/url-persistence/url-helper.js";

export default {
  components: {
    EmptyState,
    SpinningLogo,
    SuggestionsCard,
  },

  mixins: [featureFlags],
  inject: ["routes"],

  props: {
    teamIds: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapState({
      // Fetching Suggestions
      suggestions: (state) => state.serviceSuggestionsCard.suggestions,
      isFetchingSuggestions: (state) =>
        state.serviceSuggestionsCard.isFetchingSuggestions,
      filteredSuggestionsCount: (state) =>
        state.serviceSuggestionsCard.filteredSuggestionsCount,
      // Ignore Suggestions
      isUpdatingSuggestion: (state) =>
        state.serviceSuggestionsCard.isUpdatingSuggestion,
      // Action Suggestions
      isActioningSuggestions: (state) =>
        state.serviceSuggestionsCard.isActioningSuggestions,
    }),
    showGroupedSuggestions() {
      return this.hasFeatureFlag("suggestion_groups");
    },
    queryParams() {
      return {
        teamIds: this.teamIds,
        pagination: { pageSize: 3 },
        sorter: { columnKey: "suggestion_sources_count", order: "descend" },
      };
    },
    noPendingSuggestionsText() {
      return "🎉 <b>You're up to date.</b> 🎉<br/>Recommendations will appear here when we find something new for your team.";
    },
    busy() {
      return (
        this.isActioningSuggestions ||
        this.isFetchingSuggestions ||
        this.isUpdatingSuggestion
      );
    },
    filteredRecommendationsLink() {
      // `routes` param doesn't format array param properly, so we manually append parsed teamIds
      const teamParams = parseObjectToUrlParams({ teamIds: this.teamIds });

      return `${this.routes.recommendations_path()}${teamParams}`;
    },
  },

  created() {
    this.$store.registerModuleOnce(
      "serviceSuggestionsCard",
      serviceSuggestions,
    );
    this.fetchSuggestions({
      queryParams: this.queryParams,
      showGroupedSuggestions: this.showGroupedSuggestions,
    });
  },

  mounted() {
    this.$store.subscribe(this.onStoreChanged);
  },

  methods: {
    ...mapActions({
      fetchSuggestions: "serviceSuggestionsCard/fetchSuggestions",
    }),
    onStoreChanged({ type, payload }) {
      switch (type) {
        case `serviceSuggestionsCard/${RECEIVE_ACTION_SUGGESTIONS_RESULTS}`:
          this.handleActionSuggestionResults(payload);
          this.fetchSuggestions({
            queryParams: this.queryParams,
            showGroupedSuggestions: this.showGroupedSuggestions,
          });
          break;
        case `serviceSuggestionsCard/${RECEIVE_ACTION_SUGGESTIONS_ERROR}`:
          this.handleActionSuggestionError(payload);
          break;
        case `serviceSuggestionsCard/${RECEIVE_UPDATE_SUGGESTION_SUCCESS}`:
          this.fetchSuggestions({
            queryParams: this.queryParams,
            showGroupedSuggestions: this.showGroupedSuggestions,
          });

          this.$notification.success({
            message: this.$createElement("p", [
              "Successfully updated suggestion ",
              this.$createElement(
                "a",
                { attrs: { href: this.suggestionLink(payload) } },
                payload.name,
              ),
            ]),
            duration: 10,
          });
          break;
        case `serviceSuggestionsCard/${RECEIVE_UPDATE_SUGGESTION_ERROR}`:
          payload.forEach((error) => {
            this.$message.error(
              `Failed to update suggestion: ${error.message}`,
            );
          });
          break;
        default:
          break;
      }
    },
    suggestionLink(suggestion) {
      return this.routes.recommendations_path({
        highlightedSuggestionId: suggestion.id,
      });
    },
    handleActionSuggestionResults({ actioned, notActioned }) {
      if (notActioned.length) {
        this.$message.error("Recommendation was not actioned.");
      } else if (actioned.length > 0) {
        const [action] = actioned;
        const serviceLink = this.$createElement(
          "a",
          { attrs: { href: action.url } },
          action.name,
        );

        this.$notification.success({
          message: this.$createElement("p", [
            "Successfully accepted recommendation.",
          ]),
          description: serviceLink,
          duration: 20,
        });
      }
    },
    handleActionSuggestionError(errors) {
      if (errors?.length) {
        this.$message.error(`Could not action recommendation on ${errors}.`);
      }
    },
  },
};
</script>

<style scoped>
.no-teams {
  margin: auto;
  text-align: center;
}

.call-to-action-image {
  width: 200px;
}
.loading-spinner {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
</style>
