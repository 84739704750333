<template>
  <span class="services-link">
    Appears in
    <span v-if="globallyPinned">
      <a :href="routes.documentation_path({ documentId })">Docs Hub,</a>
    </span>
    <span v-for="(resource, index) in attachedResources" :key="index">
      <span v-if="index < attachedResources.length">
        <a :href="documentLinkInOpsLevel(resource.htmlUrl)">{{
          resource.name
        }}</a
        ><template v-if="addComma(index)">, </template>
      </span>
    </span>
    <span v-if="notDisplayedResourcesCount > 0">
      <a-spin v-if="isLoadingResources" />
      <span v-else-if="!isLoadingResources && isGlobalDocs">
        and {{ notDisplayedResourcesCount }} more.</span
      >
      <a v-else class="fetch-more-link" @click="fetchMoreAttachedResources">
        and {{ notDisplayedResourcesCount }} more.
      </a>
    </span>
  </span>
</template>

<script>
export default {
  inject: ["routes"],

  props: {
    globallyPinned: {
      required: false,
      type: Boolean,
      default: false,
    },
    attachedResources: {
      required: false,
      type: Array,
      default: () => [],
    },
    attachedResourcesCount: {
      required: false,
      type: Number,
      default: 0,
    },
    isLoadingResources: {
      required: false,
      type: Boolean,
      default: false,
    },
    documentId: {
      required: true,
      type: String,
    },
    isGlobalDocs: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  computed: {
    notDisplayedResourcesCount() {
      if (!this.attachedResourcesCount) {
        return 0;
      }

      return this.attachedResourcesCount - this.attachedResources.length;
    },
  },

  methods: {
    addComma(index) {
      return (
        index < this.attachedResources.length - 1 ||
        this.attachedResourcesCount > this.attachedResources.length
      );
    },
    documentLinkInOpsLevel(resourceHtmlUrl) {
      return `${resourceHtmlUrl}/tech-docs?documentId=${this.documentId}`;
    },
    fetchMoreAttachedResources() {
      this.$emit("fetchMoreAttachedResources", this.documentId);
    },
  },
};
</script>

<style scoped>
.services-link {
  font-size: 12px;
  color: #8c8c8c;
  font-weight: normal;
}
</style>
