import * as AntColors from "@ant-design/colors";

export const COLORS = {
  blue: "#1890FF",
  green: "#52C41A",
  yellow: "#FACC14",
  orange: "#FA8C16",
  red: "#F5222D",
  purple: "#722ED1",
  lightGrey: "#E9E9E9",
  midGrey: "#D9D9D9",
  darkGrey: "#8C8C8C",
  textGrey: "#000000D9",
  greyCardHeader: "#FAFAFA",
  buttonBlue: "#40A9FF",
};

export const ICON_SWATCH_COLORS = {
  [AntColors.red[4]]: "red",
  [AntColors.volcano[4]]: "volcano",
  [AntColors.orange[4]]: "orange",
  [AntColors.gold[4]]: "gold",
  [AntColors.lime[4]]: "lime",
  [AntColors.green[4]]: "green",
  [AntColors.cyan[4]]: "cyan",
  [AntColors.blue[4]]: "blue",
  [AntColors.geekblue[4]]: "geekblue",
  [AntColors.purple[4]]: "purple",
  [AntColors.magenta[4]]: "magenta",
  [AntColors.grey[4]]: "grey",
};
export const ICON_DEFAULT_COLOR = AntColors.blue[4];
